<template>
  <div class="hero">
    <JobSearchBar />
  </div>
</template>

<style scoped lang="scss">
  .hero {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 4;

    padding-top: var(--mp-space-70);
    padding-bottom: var(--mp-space-100);
    padding-left: var(--site-content-horizontal-padding);
    padding-right: var(--site-content-horizontal-padding);
    background-color: var(--mp-color-secondary-dark);

    @include screen-md {
      padding-top: var(--mp-space-90);
    }

    @include dark-mode {
      background-color: var(--mp-color-surface-50);
    }
  }
</style>
