export function isCurrentPath(path: `/${string}`) {
  const routeName =
    path === '/'
      ? 'index'
      : path.replaceAll('/', '-').replaceAll(':', '').substring(1, path.length);

  const route = useRoute();
  const getRouteBaseName = useRouteBaseName();
  const routeBaseName = getRouteBaseName(route);

  return routeBaseName === routeName;
}

export function isCurrentPathAnyOf(...paths: `/${string}`[]) {
  return paths.some((path) => isCurrentPath(path));
}
