<script setup lang="ts">
  import { MpButton } from '@borg/ui';

  const userStore = useUserStore();

  const hasOriginalToken = computed(() => userStore.originalToken);

  async function loginAsOriginal() {
    await userStore.loginWithOriginalToken();
    location.reload();
  }
</script>

<template>
  <MpButton
    v-if="hasOriginalToken"
    text="Login as original"
    variant="ghost"
    @click="loginAsOriginal"
  />
</template>
