<script setup lang="ts">
  import { MpBox } from '@borg/ui';
  import { isClient, useScrollLock } from '@vueuse/core';

  defineOptions({
    inheritAttrs: false,
  });

  const props = defineProps<{
    modelValue: boolean;
    position: 'left' | 'right';
    test?: string;
    triggerTest?: string;
  }>();

  defineEmits<{
    'update:modelValue': [modelValue: boolean];
  }>();

  const isLocked = useScrollLock(isClient ? document.body : undefined);

  watch(
    () => props.modelValue,
    (value) => {
      isLocked.value = value;
    },
  );
  // todo - focus+enter does nothing for now. as it can show user sidebar when user is not logged in
</script>

<template>
  <Overlay
    :visible="modelValue"
    :z-index="8"
  />
  <div
    tabindex="0"
    class="trigger"
    :data-test="triggerTest"
    @click="$emit('update:modelValue', true)"
    @keydown.escape="$emit('update:modelValue', false)"
  >
    <slot name="trigger" />
  </div>
  <ClientOnly>
    <Teleport to="#teleport">
      <Transition :name="position">
        <MpBox
          v-if="modelValue"
          :test="test"
          :class="{
            'menu': true,
            'menu--left': position === 'left',
            'menu--right': position === 'right',
          }"
          surface="50"
          @mousedown.prevent
          @outside-click="$emit('update:modelValue', false)"
        >
          <slot />
        </MpBox>
      </Transition>
    </Teleport>
  </ClientOnly>
</template>

<style scoped lang="scss">
  .trigger {
    &:focus-visible {
      border-radius: 3px;
      outline: 1px solid var(--mp-focus);
    }
  }

  .menu {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 8;
    box-shadow: var(--mp-shadow-medium);
    transition: transform 250ms;
    overflow-y: scroll;

    @include no-scrollbar;

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }

    @include screen-md {
      width: 22.5rem;
    }
  }

  .right-enter-active,
  .slide-leave-active {
    transform: translateX(0%);
  }

  .right-enter-from,
  .right-leave-to {
    transform: translateX(100%);
  }

  .left-enter-active,
  .left-leave-active {
    transform: translateX(0%);
  }

  .left-enter-from,
  .left-leave-to {
    transform: translateX(-100%);
  }
</style>
