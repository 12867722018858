export const leftSidebar = createInjector('leftSidebar', () => {
  const isOpen = ref(false);

  function toggle() {
    isOpen.value = !isOpen.value;
  }

  return reactive({
    isOpen,
    toggle,
  });
});
