<script setup lang="ts">
  import { MpDivider, MpIconButton, MpMenu, MpText } from '@borg/ui';

  const userStore = useUserStore();
  const isVisible = ref(false);
</script>

<template>
  <FlyInMenu
    v-model="isVisible"
    position="right"
    test="right-sidebar-menu-container"
  >
    <template #trigger>
      <RightUserIndicator />
    </template>
    <header class="user-menu__header">
      <Avatar
        size="md"
        class="user-menu__avatar"
      />
      <MpIconButton
        data-test="right-sidebar-close-button"
        class="user-menu__close"
        icon="close"
        variant="plain"
        color-scheme="neutral"
        @click="isVisible = false"
      />
    </header>
    <main class="user-menu__main">
      <div class="user-menu__info">
        <MpText
          as="h1"
          no-margin
          class="user-menu__name-label"
        >
          {{ userStore.user?.firstName }} {{ userStore.user?.lastName }}
        </MpText>
        <MpText shade="40">
          {{ userStore.user?.email }}
        </MpText>
      </div>
      <MpMenu>
        <MenuLink
          :to="{ name: 'my-profile' }"
          :text="$t('USER_SIDEBAR.CV_LINK_LABEL')"
          @click="isVisible = false"
        />
        <MenuLink
          to="my-applications"
          :text="$t('USER_SIDEBAR.MY_APPLICATIONS_LINK_LABEL')"
          @click="isVisible = false"
        />
        <MenuLink
          :to="{ name: 'my-favorite-jobs' }"
          :text="$t('USER_SIDEBAR.SAVED_JOBS_LINK_LABEL')"
          @click="isVisible = false"
        />
        <MenuLink
          :to="{ name: 'my-documents' }"
          :text="$t('USER_SIDEBAR.MY_DOCUMENTS_LINK_LABEL')"
          @click="isVisible = false"
        />
        <MenuLink
          :to="{ name: 'my-notifications' }"
          :text="$t('USER_SIDEBAR.NOTIFICATIONS_LINK_LABEL')"
          @click="isVisible = false"
        />
        <MenuLink
          :to="{ name: 'my-settings' }"
          :text="$t('USER_SIDEBAR.MY_PROFILE_SETTINGS_LINK_LABEL')"
          @click="isVisible = false"
        />
        <MpDivider margin="sm" />
        <LinksLocale
          :to="{ name: 'auth-logout' }"
          data-test="right-sidebar-logout"
          @click="isVisible = false"
        >
          {{ $t('USER_SIDEBAR.LOGOUT_LINK_LABEL') }}
        </LinksLocale>
        <MpDivider margin="sm" />
        <div
          class="theme"
          @click.stop
        >
          <ThemeSwitch />
        </div>
      </MpMenu>
    </main>
  </FlyInMenu>
</template>

<style scoped lang="scss">
  .theme {
    padding: 0 var(--mp-space-60);
  }

  .user-menu {
    &__header {
      position: relative;
      background-color: var(--mp-color-primary-default);
      height: 7rem;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding: var(--mp-space-50);
    }

    &__name-label {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__info {
      display: flex;
      flex-direction: column;
      gap: var(--mp-space-20);
      margin-bottom: var(--mp-space-40);
      padding: 0 var(--mp-space-60);
    }

    &__avatar {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(50%);
      margin-left: var(--mp-space-60);
      box-shadow: 0 8px 24px 0 rgba(29, 29, 31, 0.05);
      border: 4px solid var(--mp-color-surface-50);
      background-color: var(--site-right-sidebar-avatar-background);
    }

    &__close {
      --button-color-dark: var(--mp-color-grey-white);
    }

    &__main {
      margin: var(--mp-space-100) 0 var(--mp-space-60);
    }
  }
</style>
