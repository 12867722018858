<script setup lang="ts">
  import { MpText } from '@borg/ui';
  import { LinksLocale } from '#components';

  const { t } = useI18n();
  const userStore = useUserStore();

  const asComponent = computed(() => (userStore.isAuthenticated ? 'div' : LinksLocale));
  const label = computed(
    () => userStore.user?.firstName ?? userStore.user?.email ?? t('USER_SIDEBAR.LOGIN_LINK_LABEL'),
  );
  const isUserLoading = computed(
    () => userStore.loginState === 'inProgress' || userStore.initState === 'inProgress',
  );
</script>

<template>
  <component
    :is="asComponent"
    :to="{ name: 'auth-login', query: { redirect: $route.fullPath } }"
    class="controls"
    data-test="right-user-indicator"
  >
    <MpText
      class="controls__label"
      no-margin
    >
      {{ label }}
    </MpText>
    <Avatar
      size="sm"
      :is-loading="isUserLoading"
    />
  </component>
</template>

<style scoped lang="scss">
  .controls {
    display: flex;
    align-items: center;
    gap: var(--mp-space-50);
    cursor: pointer;

    &__label {
      max-width: 5.3125rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: none;

      @include screen-xl {
        display: inline-block;
      }
    }
  }
</style>
