<script setup lang="ts">
  import { RouteLocationRaw } from 'vue-router';
  import { MpIcon, MpText } from '@borg/ui';

  const props = defineProps<{
    to: RouteLocationRaw;
    text: string;
    isExternal?: boolean;
  }>();

  function getTarget() {
    return props.isExternal ? '_blank' : undefined;
  }
</script>

<template>
  <LinksLocale
    :to="to"
    class="menu-link"
    :target="getTarget()"
    :data-test="text"
  >
    <MpText link-plain>
      {{ text }}
    </MpText>
    <MpIcon
      v-if="isExternal"
      class="menu-link__icon"
      name="new_window"
      size="sm"
    />
  </LinksLocale>
</template>

<style scoped lang="scss">
  .menu-link {
    display: flex;
    align-items: center;
    gap: var(--mp-space-30);

    &__icon {
      color: var(--mp-color-secondary-default);

      @include dark-mode {
        color: var(--mp-color-secondary-light);
      }
    }
  }
</style>
