<script setup lang="ts">
  import { RouteLocationRaw } from '#vue-router';

  interface InfoLink {
    to: RouteLocationRaw;
    label: string;
  }

  const { t } = useI18n();

  const links = computed<InfoLink[]>(() => [
    {
      to: { name: 'info-slug', params: { slug: t('BRAND_LINKS.CONTACT.SLUG') } },
      label: t('BRAND_LINKS.CONTACT.LABEL'),
    },
    {
      to: 'https://www.almacareer.com/hr/pridruzite-nam-se',
      label: t('BRAND_LINKS.MEET_US.LABEL'),
    },
    {
      to: { name: 'info-slug', params: { slug: t('BRAND_LINKS.TOS.SLUG') } },
      label: t('BRAND_LINKS.TOS.LABEL'),
    },
    {
      to: { name: 'info-slug', params: { slug: t('BRAND_LINKS.PRIVACY.SLUG') } },
      label: t('BRAND_LINKS.PRIVACY.LABEL'),
    },
  ]);
</script>

<template>
  <slot :links="links" />
</template>
