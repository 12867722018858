<script setup lang="ts">
  import { MpButton, MpCard, MpText, pushToastMessage, useMpScreen } from '@borg/ui';

  const { isMobile } = useMpScreen();
  const { data: applications, refresh } = await pendingApplicationsRepository.getMany();
  const activeApplicationIndex = ref(0);
  const displayedApplication = computed(() => applications.value?.[activeApplicationIndex.value]);
  const { t } = useI18n();

  const cardRef = ref<InstanceType<typeof MpCard>>();

  function onComplete() {
    if (displayedApplication.value) {
      navigateToJobApplication(
        displayedApplication.value.job.id,
        displayedApplication.value.job.slug,
        { source: 'header_continue' },
      );
    }
  }

  async function onCancel() {
    activeApplicationIndex.value = activeApplicationIndex.value + 1;
    const applicationToDelete = applications.value?.[activeApplicationIndex.value - 1];

    if (applicationToDelete) {
      try {
        await pendingApplicationsRepository.delete(applicationToDelete.id);
      } catch (e) {
        logger.error(`Error trying to delete pending application: ${e}`);
        pushToastMessage({
          type: 'warning',
          title: t('PENDING_JOB_APPLICATION.DELETE_PENDING_APPLICATION_ERROR.TITLE'),
          subtitle: t('PENDING_JOB_APPLICATION.DELETE_PENDING_APPLICATION_ERROR.SUBTITLE'),
        });
        refresh();
      }

      if (!applications.value || activeApplicationIndex.value === applications.value.length) {
        await refresh();
        activeApplicationIndex.value = 0;
      }
    }
  }
</script>

<template>
  <Transition name="outer">
    <div
      v-if="!!displayedApplication"
      class="pending-container"
    >
      <MpCard
        ref="cardRef"
        :p="isMobile ? 'sm' : 'xs'"
        corners="square"
        class="pending-job"
      >
        <MpText
          type="h6"
          no-margin
        >
          {{ $t('PENDING_JOB_APPLICATION.FINISH_LABEL') }}:
          {{ displayedApplication.job.name }}
        </MpText>
        <div class="pending-job__actions">
          <MpButton
            :text="$t('PENDING_JOB_APPLICATION.CANCEL_BUTTON_LABEL')"
            size="sm"
            variant="secondary"
            @click="onCancel"
          />
          <MpButton
            :text="$t('PENDING_JOB_APPLICATION.CONTINUE_BUTTON_LABEL')"
            size="sm"
            @click="onComplete"
          />
        </div>
      </MpCard>
    </div>
  </Transition>
</template>

<style scoped lang="scss">
  .pending-container {
    position: relative;
    z-index: 3;
    background-color: var(--site-header-background-color);
  }

  .pending-job {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--mp-space-40);
    background-color: var(--mp-color-system-a-dark);

    &__actions {
      display: flex;
      gap: var(--mp-space-30);
    }

    @include screen-md {
      flex-direction: row;
    }

    @include dark-mode {
      background-color: var(--mp-color-surface-10);
    }
  }

  .outer-enter-active,
  .outer-leave-active {
    transition: all 250ms ease-out;
  }

  .outer-enter-from,
  .outer-leave-to {
    transform: translateY(-100%);
  }
</style>
