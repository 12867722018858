<script setup lang="ts">
  import { MpThemeSwitch } from '@borg/ui';

  const { t } = useI18n();
  const siteTheme = useSiteTheme();

  function setTheme(dark: boolean) {
    siteTheme.set(dark ? 'dark' : 'light');
  }
</script>

<template>
  <MpThemeSwitch
    data-test="theme-switch"
    class="theme-switcher__switch"
    :model-value="siteTheme.theme === 'dark'"
    :label="t('USER_SIDEBAR.SITE_THEME_DARK_LABEL')"
    label-position="right"
    @update:model-value="setTheme"
  />
</template>
