import { PendingApplication } from '@borg/types';

class PendingApplicationsRepository extends Repository<PendingApplication> {
  getMany() {
    return this._getMany({ url: '/applications/me/pending' });
  }

  delete(id: string) {
    return this._delete({ url: `/applications/pending/${id}` });
  }
}

export const pendingApplicationsRepository = new PendingApplicationsRepository();
